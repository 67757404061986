import { ItemArtist } from '@/api';
import clsx from 'clsx';
import React from 'react';

const ArtistTitle = ({ artist, variant, colors }: { artist?: ItemArtist, variant: number; colors?: string; }) => {
    if (!artist) {
        return null;
    }
    return (
        <a
            onClick={(e) => e.stopPropagation()}
            href={`/marketplace?artists=${encodeURIComponent(artist.slug)}`}
            className={clsx('font-secondary block font-[500] text-[14px] text-grey15')}>{artist.name}
        </a>
    );
};

export default ArtistTitle;
